import Vue from 'vue';
import { LMap, LControl, LControlLayers, LControlZoom, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import LTileLayerGoogleMutant from 'vue2-leaflet-googlemutant';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import LeafletMap from 'src/components/Map';
window.L = require('leaflet');
Vue.component('l-map', LMap);
Vue.component('leaflet-map', LeafletMap);
Vue.component('l-control', LControl);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-tile-layer-googlemutant', LTileLayerGoogleMutant);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster);

// fix icons
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
Vue.component('GmapCluster', GmapCluster);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_API_KEY || 'AIzaSyAt-5tQM_uYRx7Q1cFGOMbtNx2fq7sdZz8',
    autobindAllEvents: false
  },
  installComponents: true
});