import "core-js/modules/es6.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import L from 'leaflet';
import { each, findIndex, debounce } from 'lodash';
export default {
  props: {
    markers: {
      type: Array
    },
    bounds: {
      default: function _default() {
        return '';
      }
    },
    selectedMarker: {
      type: Object
    },
    center: {
      type: Object,
      default: function _default() {
        return {
          lat: 44.645647,
          lng: 10.923173
        };
      }
    },
    mapZoom: {
      default: function _default() {
        return 14;
      }
    }
  },
  data: function data() {
    return {
      apiKey: process.env.GOOGLE_API_KEY || 'AIzaSyAt-5tQM_uYRx7Q1cFGOMbtNx2fq7sdZz8',
      map: null,
      // center: L.latLng(44.645647, 10.923173),
      clusterOptions: {
        disableClusteringAtZoom: 16,
        spiderfyOnMaxZoom: false,
        iconCreateFunction: this.iconCreateFunction
      },
      popupOptions: {
        closeButton: true,
        minWidth: 300,
        autoPanPadding: L.point(window.innerWidth * 0.05, 50)
      },
      mapOptions: {
        zoomControl: false,
        layerControl: true,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: [{
          featureType: 'poi',
          stylers: [{
            visibility: 'off'
          }]
        }, {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{
            visibility: 'on'
          }]
        }]
      },
      clusterMarkers: [{
        width: 32,
        height: 32,
        url: '/statics/images/markers/marker-cluster.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-b.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-r.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-y.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-br.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-by.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-ry.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-bry.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-b-sm.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-r-sm.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-y-sm.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-br-sm.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-by-sm.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-ry-sm.png'
      }, {
        width: 40,
        height: 40,
        url: '/statics/images/markers/marker-cluster-bry-sm.png'
      }]
    };
  },
  watch: {
    center: function center(val) {
      if (!this.map) return;
      this.map.setCenter(val);
    }
  },
  methods: {
    // NOTE: OLDWARE Leaflet
    initMap: function initMap() {
      this.map = this.$refs.myMap.mapObject;
      this.map.locate({
        setView: true,
        maxZoom: 12
      });
    },
    getMarkerIcon: function getMarkerIcon(construction) {
      var iconName = 'marker-single-';
      if (construction.commercial) {
        iconName += 'b';
      }
      if (construction.terrain) {
        iconName += 'r';
      }
      if (construction.residential) {
        iconName += 'y';
      }
      if (construction.status === 'in_history') {
        iconName = 'marker-single-bla';
      }
      if (this.selectedMarker && construction.id === this.selectedMarker.id) {
        iconName += '-active';
      }
      return '/statics/images/markers/' + iconName + '.png';
    },
    markerIconCalculator: function markerIconCalculator(markers, numStyles) {
      var iconName = 'marker-cluster-';
      var residential = false;
      var commercial = false;
      var terrain = false;
      each(markers, function (marker) {
        residential |= marker.construction.residential;
        commercial |= marker.construction.commercial;
        terrain |= marker.construction.terrain;
      });
      if (commercial) {
        iconName += 'b';
      }
      if (terrain) {
        iconName += 'r';
      }
      if (residential) {
        iconName += 'y';
      }

      // if (markers.getChildCount() < 5) {
      //   iconName += '-sm'
      // }
      return {
        text: '',
        index: findIndex(this.clusterMarkers, {
          url: '/statics/images/markers/' + iconName + '.png'
        }) + 1
      };
    },
    iconCreateFunction: function iconCreateFunction(cluster) {
      var iconName = 'marker-cluster-';
      var markers = cluster.getAllChildMarkers();
      var residential = false;
      var commercial = false;
      var terrain = false;
      each(markers, function (marker) {
        residential |= marker.options.construction.residential;
        commercial |= marker.options.construction.commercial;
        terrain |= marker.options.construction.terrain;
      });
      if (commercial) {
        iconName += 'b';
      }
      if (terrain) {
        iconName += 'r';
      }
      if (residential) {
        iconName += 'y';
      }
      if (cluster.getChildCount() < 5) {
        iconName += '-sm';
      }
      return L.icon({
        iconUrl: '/statics/images/markers/' + iconName + '.png'
      });
    },
    updateBounds: function updateBounds() {
      if (this.map) {
        // debugger
        this.$emit('update:bounds', JSON.stringify({
          type: 'Polygon',
          coordinates: [[[this.map.getBounds().getSouthWest().lng(), this.map.getBounds().getNorthEast().lat()], [this.map.getBounds().getNorthEast().lng(), this.map.getBounds().getNorthEast().lat()], [this.map.getBounds().getNorthEast().lng(), this.map.getBounds().getSouthWest().lat()], [this.map.getBounds().getSouthWest().lng(), this.map.getBounds().getSouthWest().lat()], [this.map.getBounds().getSouthWest().lng(), this.map.getBounds().getNorthEast().lat()]]],
          center: "".concat(this.map.getCenter().lng(), ", ").concat(this.map.getCenter().lat())
        }));
      }
    },
    closeInfoWindows: function closeInfoWindows() {
      this.$refs.infoWindow.forEach(function (iw) {
        if (iw.opened) {
          iw.opened = false;
        }
      });
      this.selectMarker(null);
    },
    selectMarker: function selectMarker(m) {
      if (this.$q.screen.gt.xs && m) {
        m.showInfo = true;
      }
      // m.selected = true
      this.$emit('update:selectedMarker', m);
    },
    openConstruction: function openConstruction(construction) {
      construction.$incrementPopupVisits();
      window.open(construction.url + '?prevent_increment=1', '_blank');
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$refs.myMap.$mapPromise.then(function (map) {
      _this.map = map;
      _this.map.addListener('bounds_changed', debounce(_this.updateBounds, 300));
    });
  }
};