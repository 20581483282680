import { mapState } from 'vuex';
export default {
  namespaced: true,
  state: {
    viewName: 'Trovacantieri',
    userIsSubscribed: false,
    accessToken: null,
    locale: 'it',
    lastResultsRoute: null
  },
  computed: mapState([
  // map this.count to store.state.count
  'accessToken', 'locale', 'lastResultsRoute']),
  mutations: {
    setViewName: function setViewName(state, payload) {
      state.viewName = payload;
    },
    setUserIsSubscribed: function setUserIsSubscribed(state, subscribed) {
      state.userIsSubscribed = subscribed;
    },
    setAccessToken: function setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setLastResultsRoute: function setLastResultsRoute(state, lastResultsRoute) {
      state.lastResultsRoute = lastResultsRoute;
    }
  }
};