var it = {
  'Home': 'Home',
  'Contatti': 'Contatti',
  'Come funziona': 'Come funziona',
  'Vantaggi': 'Vantaggi',
  'Cerca': 'Cerca',
  'Case in vendita': 'Case in vendita',
  'di Nuova Costruzione': 'di Nuova Costruzione',
  'Proposte Direttamente dalle Imprese': 'Proposte Direttamente dalle Imprese',
  'I vantaggi': 'I vantaggi',
  'Risparmia tempo e denaro acquistando direttamente dai costruttori': 'Risparmia tempo e denaro acquistando direttamente dai costruttori',
  'Più informazioni': 'Più informazioni',
  'zona': 'zona',
  rooms_extended: '0 camere | 1 camera | {rooms} camere',
  bathrooms_extended: '0 bagni | 1 bagno | {rooms} bagni',
  constructions_count: 'Nessun cantiere | 1 cantiere | {constructions_count} cantieri',
  construction: {
    construction_progress: {
      in_construction: 'In costruzione',
      completed: 'Finito',
      design_in_progress: 'In progettazione'
    }
  },
  app: {
    generals: {
      city: 'Città',
      state: 'Provincia'
    },
    privacy_policy: 'Presa visione dell\'informativa sulla privacy'
  },
  request_info_contact_info: 'La società <b>{contact_name}</b> riceverà una email con la tua richiesta di informazioni',
  typology_request_info_contact_info: 'Trovacantieri invierà una email alle società responsabili delle informazioni per la tipologia {typology_code}'
};
export default it;