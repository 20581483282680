//
//
//
//
//
//

export default {
  name: 'App',
  data: function data() {
    return {
      pixelId: process.env.FB_PIXEL_ID
    };
  },
  methods: {
    createFBScript: function createFBScript(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }
  },
  beforeMount: function beforeMount() {
    this.createFBScript(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  },
  mounted: function mounted() {
    window.fbq('init', this.pixelId);
    window.fbq('track', 'PageView');
  }
};