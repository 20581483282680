import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import User from "./User";
import State from "./State";
import City from "./City";
import Image from "./Image";
import Construction from "./Construction";
import Typology from "./Typology";
import TypologyCategory from "./TypologyCategory";
import Company from "./Company";
import Subscription from "./Subscription";
import Product from "./Product";
import Price from "./Price";
import Contact from "./Contact";
import ConstructionContact from "./ConstructionContact";
import CompanyUser from "./CompanyUser";
import Invoice from "./Invoice";
import Issue from "./Issue";
import AdBanner from "./AdBanner";
import ContactAgent from "./ContactAgent";
import ConstructionContactAgent from "./ConstructionContactAgent";
import ConstructionFlyer from "./ConstructionFlyer";
import FlyerTypology from "./FlyerTypology";
import FlyerImage from "./FlyerImage";
import Flyer from "./Flyer";
import Order from "./Order";
import app from "../modules/app";
import pagination from "../modules/pagination";
Vue.use(Vuex);

// Create new instance of Database.
var database = new VuexORM.Database();

// Register Model and Module. The First argument is the Model, and
// second is the Module.
// register User Model
database.register(User, app);
database.register(State, app);
database.register(City, app);
database.register(Image, app);
database.register(Construction, pagination);
database.register(Typology, app);
database.register(TypologyCategory, app);
database.register(Company, app);
database.register(Subscription, app);
database.register(Product, app);
database.register(Price, app);
database.register(Contact, app);
database.register(ConstructionContact, app);
database.register(CompanyUser, app);
database.register(Invoice, app);
database.register(Issue, app);
database.register(AdBanner, app);
database.register(ContactAgent, app);
database.register(ConstructionFlyer, app);
database.register(FlyerTypology, app);
database.register(FlyerImage, app);
database.register(Flyer, app);
database.register(Order, app);
database.register(ConstructionContactAgent, app);
export default database;