import Vuex from 'vuex';
import Vue from 'vue';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import database from "./models";
import app from "./modules/app";
import pagination from "./modules/pagination";
VuexORM.use(VuexORMAxios, {
  database: database,
  axios: Vue.prototype.$axios
});
var store = null;

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function /* { ssrContext } */
() {
  var Store = new Vuex.Store({
    namespaced: true,
    modules: {
      app: app,
      pagination: pagination
    },
    plugins: [VuexORM.install(database)]
  });
  store = Store;
  return Store;
}
export { store };