import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from 'src/i18n';
Vue.use(VueI18n);
var i18n = new VueI18n({
  locale: 'it',
  fallbackLocale: 'it',
  messages: messages,
  silentTranslationWarn: true
});
export default (function (_ref) {
  var app = _ref.app;
  // Set i18n instance on app
  app.i18n = i18n;
});

// if you need to import it from
// other files, then:
export { i18n };