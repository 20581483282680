var routes = [{
  path: '/',
  component: function component() {
    return import('layouts/App.vue');
  },
  meta: {
    auth: false,
    ssr: true
  },
  children: [{
    path: '/',
    component: function component() {
      return import('pages/index.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/non-trovato',
    component: function component() {
      return import('pages/Error404.vue');
    },
    name: 'not-found',
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/cantiere/:webCode',
    component: function component() {
      return import('pages/Construction.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/ricerca',
    component: function component() {
      return import('pages/Results.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/contatti',
    component: function component() {
      return import('pages/ContactUs.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/vantaggi',
    component: function component() {
      return import('pages/Advantages.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/come-funziona',
    component: function component() {
      return import('pages/HowItWorks.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/costruttore/:companySlug/lista',
    component: function component() {
      return import('pages/Results.vue');
    },
    redirect: {
      path: '/costruttore/:companySlug/altre-proposte'
    },
    name: 'costruttore_lista'
  }, {
    path: '/costruttore/:companySlug',
    redirect: {
      path: '/costruttore/:companySlug/altre-proposte'
    },
    component: function component() {
      return import('pages/Results.vue');
    },
    meta: {
      auth: false,
      ssr: true
    },
    name: 'costruttore'
  }, {
    path: '/costruttore/:companySlug/altre-proposte',
    component: function component() {
      return import('pages/Results.vue');
    },
    meta: {
      auth: false,
      ssr: true
    },
    name: 'altre_proposte_costruttore'
  }, {
    path: '/costruttore/:companySlug/referenze',
    component: function component() {
      return import('pages/Results.vue');
    },
    meta: {
      auth: false,
      ssr: true
    },
    name: 'referenze'
  }, {
    path: '/:stateSlug',
    component: function component() {
      return import('pages/Results.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }, {
    path: '/:stateSlug/:citySlug',
    component: function component() {
      return import('pages/Results.vue');
    },
    meta: {
      auth: false,
      ssr: true
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('layouts/Blank.vue');
  },
  children: [{
    path: '',
    component: function component() {
      return import('pages/Login.vue');
    }
  }, {
    path: '/costruttore/:companySlug/widget',
    component: function component() {
      return import('pages/Widget.vue');
    },
    meta: {
      auth: false,
      ssr: true
    },
    name: 'costruttore_widget'
  }]
}];

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: function component() {
      return import('pages/Error404.vue');
    }
  });
}
export default routes;