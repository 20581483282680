import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.array.map.js";
import Vue from 'vue';
import moment from 'moment-timezone';
import _ from 'lodash';
Vue.filter('currency', function (value) {
  value = value || 0;
  return '€ ' + parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
});
Vue.filter('split', function (value, divider) {
  return _.map(value, 'mail').join(divider);
});
Vue.filter('formatDate', function (date, format) {
  return moment(date).format(format);
});
Vue.filter('truncate', function (text, maxLength) {
  var open = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!open) {
    return _.truncate(text, {
      length: maxLength,
      separator: /,? +/
    });
  } else {
    return text;
  }
});