import _defineProperty from "/builds/trovacantieri/trovacantieri-frontend/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
var _mutations;
/**
 * Mutations that are used in this application module.
 */
var SET_PAGINATION = 'SET_PAGINATION';
var RESET_PAGINATION = 'RESET_PAGINATION';
var PAGINATION_CURRENT_PAGE = 1;
var PAGINATION_PER_PAGE = 15;
var PAGINATION_TOTAL = 0;
var PAGINATION_LAST_PAGE = 1;
export default {
  namespaced: true,
  /**
   * Defines the state being monitored for the module.
   */
  state: {
    current_page: PAGINATION_CURRENT_PAGE,
    last_page: PAGINATION_LAST_PAGE,
    per_page: PAGINATION_PER_PAGE,
    total: PAGINATION_TOTAL
  },
  /**
   * Defines the getters used by the module.
   */
  getters: {
    current_page: function current_page(state) {
      return state.current_page;
    },
    last_page: function last_page(state) {
      return state.last_page;
    },
    per_page: function per_page(state) {
      return state.per_page;
    },
    total: function total(state) {
      return state.total;
    }
  },
  /**
   * Defines the mutations used by the module.
   */
  mutations: (_mutations = {}, _defineProperty(_mutations, SET_PAGINATION, function (state, meta) {
    state.current_page = parseInt(meta.current_page, 10);
    state.last_page = parseInt(meta.last_page, 10);
    state.per_page = parseInt(meta.per_page, 10);
    state.total = parseInt(meta.total, 10);
  }), _defineProperty(_mutations, RESET_PAGINATION, function (state) {
    state.current_page = PAGINATION_CURRENT_PAGE;
    state.last_page = PAGINATION_LAST_PAGE;
    state.per_page = PAGINATION_PER_PAGE;
    state.total = PAGINATION_TOTAL;
  }), _mutations),
  /**
   * Defines the actions used to retrieve the data.
   */
  actions: {
    setPagination: function setPagination(context, meta) {
      context.commit(SET_PAGINATION, meta);
    },
    resetPaginator: function resetPaginator(context) {
      context.commit(RESET_PAGINATION);
    }
  }
};