import "core-js/modules/es6.regexp.replace.js";
export default (function (_ref) {
  var app = _ref.app,
    router = _ref.router,
    store = _ref.store,
    Vue = _ref.Vue;
  Vue.mixin({
    computed: {
      isMobile: function isMobile() {
        return this.$q.screen.lt.sm;
      }
    },
    methods: {
      goBack: function goBack() {
        this.$router.back();
      },
      parsePhoneNumber: function parsePhoneNumber(phoneNumber) {
        // keep only digits and plus sign
        return phoneNumber.replace(/[^\d+]/g, '');
      }
    }
  });
});