import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "./routes";
Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default function (_ref) {
  var store = _ref.store,
    ssrContext = _ref.ssrContext;
  var Router = new VueRouter({
    scrollBehavior: function scrollBehavior() {
      return {
        x: 0,
        y: 0
      };
    },
    routes: routes,
    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  });
  Router.beforeEach(function (to, from, next) {
    if (to.matched.some(function (record) {
      return record.meta.auth;
    })) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      // let token = localStorage.getItem('access_token')
      var token = ssrContext.$q.cookies.get('access_token');
      if (!token || typeof token === 'undefined') {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
  return Router;
}